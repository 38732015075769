import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import slugify from 'slugify';
import { last } from 'lodash';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import Columns from 'components/columns';
import Divider from 'components/divider';
import Type from 'components/type';
import Head from 'components/head';

import { Container, Group, Item } from 'components/app-nav/app-nav.css';

const Recognition = ({ data }) => {
  const [picked, setPicked] = useState(
    data.allContentfulRecognitionPage.edges.length - 1
  );
  // const pickedData = last(data.allContentfulRecognitionPage.edges).node;
  const pickedData = data.allContentfulRecognitionPage.edges[picked].node;
  const { recipient } = pickedData;
  return (
    <PageWrapper bg="bg-info select-infoBright" withAlert>
      <Head pageTitle="Recognition" />
      <Box
        width="max-w-full pt-9 sm:pt-14 px-9 sm:px-14"
        flex="flex flex-col items-center"
      >
        <Group margin="mt-14">
          {data.allContentfulRecognitionPage.edges.map(({ node }, i) => (
            <Item
              key={node.id}
              active={node.id === pickedData.id ? 'bg-info' : 'bg-infoBright'}
              border={
                i !== data.allContentfulRecognitionPage.edges.length - 1
                  ? 'border-r'
                  : ''
              }
              style={{ fontVariantNumeric: 'lining-nums' }}
              onClick={() => setPicked(i)}
            >
              {node.title}
            </Item>
          ))}

          {/* <Item style={{ fontVariantNumeric: 'lining-nums' }}>2024</Item> */}
        </Group>

        <Box width="pt-14 px-6 md:px-16 max-w-xl xl:max-w-2xl">
          <Type align="text-center" size="large">
            {pickedData.headline &&
              documentToReactComponents(pickedData.headline.json)}
          </Type>
          <Divider />
        </Box>
        <Box width="px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
          <Type as="h3" align="text-left" size="large" spacing="mb-10">
            The Recipient
          </Type>
          <Box
            width={`max-w-full block mb-8 bi-avoid ${recipient.images.length >
              1 && 'col-span-all'}`}
            flex="flex flex-col"
            key={recipient.id}
            override={
              recipient.id === '03233cbc-a400-5aa9-be13-d4bffe76220b' &&
              'col-span-all'
            }
          >
            {recipient.nameUrl ? (
              <a
                href={recipient.nameUrl}
                className="text-center self-center no-underline"
              >
                <Type
                  as="h4"
                  size="small-serif"
                  spacing="text-center custom-underline-black hover:custom-underline-none inline"
                >
                  {recipient.name}
                </Type>
              </a>
            ) : (
              <Type
                as="h4"
                size="small-serif"
                spacing="text-center custom-underline-black inline self-center"
              >
                {recipient.name}
              </Type>
            )}
            <div className={'flex flex-col md:flex-row items-start'}>
              {recipient.images && recipient.images[0] && (
                <Img
                  className={'mt-6 w-full md:w-1/2 md:mr-8'}
                  key={recipient.images[0].id}
                  fluid={recipient.images[0].fluid}
                />
              )}

              <div className={'md:w-1/2 md:ml-8'}>
                <Type size="small-serif" spacing="mt-8 md:mt-6">
                  {recipient.role}
                </Type>
                <Type spacing="mt-4">
                  {documentToReactComponents(recipient.description.json)}
                </Type>
              </div>
            </div>
            {recipient.optionalLeft && (
              <Type spacing="mt-4">
                <div className="bi-avoid">
                  {documentToReactComponents(recipient.optionalLeft.json)}
                </div>
              </Type>
            )}
            {recipient.optionalRight && (
              <Type spacing="">
                <div className="bi-avoid">
                  {documentToReactComponents(recipient.optionalRight.json)}
                </div>
              </Type>
            )}
          </Box>
          <Divider />
          <Type as="h3" align="text-left" size="large" spacing="mb-4">
            The Finalists
          </Type>
          <Type align="text-left" size="small" spacing="mb-10">
            {documentToReactComponents(pickedData.finalistHeadline.json)}
          </Type>
          <div>
            {pickedData.finalists.map(
              (
                {
                  id,
                  name,
                  nameUrl,
                  images,
                  description,
                  role,
                  optionalLeft,
                  optionalRight,
                },
                i
              ) => (
                <Box
                  width={`max-w-full block mb-8 bi-avoid ${images.length > 1 &&
                    'col-span-all'}`}
                  flex="flex flex-col"
                  key={id}
                  override={
                    id === '03233cbc-a400-5aa9-be13-d4bffe76220b' &&
                    'col-span-all'
                  }
                >
                  {nameUrl ? (
                    <a
                      href={nameUrl}
                      className="text-center self-center no-underline"
                    >
                      <Type
                        as="h4"
                        size="small-serif"
                        spacing="text-center custom-underline-black hover:custom-underline-none inline"
                      >
                        {name}
                      </Type>
                    </a>
                  ) : (
                    <Type
                      as="h4"
                      size="small-serif"
                      spacing="text-center custom-underline-black inline self-center"
                    >
                      {name}
                    </Type>
                  )}
                  <div
                    className={`flex flex-col ${
                      i % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                    } items-start`}
                  >
                    {images && images[0] && (
                      <Img
                        className={`mt-6 w-full md:w-1/2  ${
                          i % 2 === 0 ? 'md:mr-8' : 'md:ml-8'
                        }`}
                        key={images[0].id}
                        fluid={images[0].fluid}
                      />
                    )}

                    <div
                      className={`md:w-1/2 ${
                        i % 2 === 0 ? 'md:ml-8' : 'md:mr-8'
                      }`}
                    >
                      <Type size="small-serif" spacing="mt-8 md:mt-6">
                        {role}
                      </Type>
                      <Type spacing="mt-4">
                        {documentToReactComponents(description.json)}
                      </Type>
                    </div>
                  </div>
                  {optionalLeft && (
                    <Type spacing="mt-4">
                      <div className="bi-avoid">
                        {documentToReactComponents(optionalLeft.json)}
                      </div>
                    </Type>
                  )}
                  {optionalRight && (
                    <Type spacing="">
                      <div className="bi-avoid">
                        {documentToReactComponents(optionalRight.json)}
                      </div>
                    </Type>
                  )}
                </Box>
              )
            )}
          </div>
          <Divider />
          <Type as="h3" align="text-left" size="large" spacing="mb-4">
            {picked > 1
              ? 'Special Recognition'
              : 'Women of Typographic Excellence'}
          </Type>
          <Type align="text-left" size="small" spacing="mb-10">
            {documentToReactComponents(
              pickedData.typographicExcellenceHeadline.json
            )}
          </Type>
          <Type as="ul" spacing="list-none ml-0 text-center md:col-count-2">
            {pickedData.excellenceLinks.map(item => (
              <Type
                as="li"
                spacing="pl-0 mb-1"
                size="small-serif"
                key={slugify(item.id, { lower: true })}
              >
                {item.linkUrl ? (
                  <a
                    href={item.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:no-underline"
                  >
                    {item.text}
                  </a>
                ) : (
                  item.text
                )}
              </Type>
            ))}
          </Type>
        </Box>
      </Box>
    </PageWrapper>
  );
};

Recognition.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Recognition;

export const query = graphql`
  query RecognitionQuery {
    allContentfulRecognitionPage(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          headline {
            json
          }
          recipient {
            id
            name
            nameUrl
            role
            images {
              id
              fluid(maxWidth: 1280) {
                ...GatsbyContentfulFluid
              }
            }
            description {
              id
              json
            }
            optionalLeft {
              id
              json
            }
            optionalRight {
              id
              json
            }
          }
          finalistHeadline {
            json
          }
          finalists {
            id
            name
            nameUrl
            role
            images {
              id
              fluid(maxWidth: 1280) {
                ...GatsbyContentfulFluid
              }
            }
            description {
              id
              json
            }
            optionalLeft {
              id
              json
            }
            optionalRight {
              id
              json
            }
          }
          typographicExcellenceHeadline {
            json
          }
          excellenceLinks {
            id
            text
            linkUrl
          }
        }
      }
    }
  }
`;
